//import { mq, variables } from 'cssInJs';

export default {
    contactForm : {
        maxWidth: '960px',
        margin:'auto',
        color: 'white',
        'span.req' : {
            display: 'none'
        }
    }
}