import { fb, validators } from '../../../../lib/form';

export function getProposalFormModel(data) {
    
    const interestedList = [
        { name: 'Website / Progressive Web App', value: 'Website / Progressive Web App' },
        { name: 'Online Shop (E-Commerce)', value: 'Online Shop (E-Commerce)' },
        { name: 'Branding & Graphic Design', value: 'Branding & Graphic Design' },
        { name: 'Digital Marketing', value: 'Digital Marketing' },
        { name: 'Photography', value: 'Photography' },
        { name: 'Copywriting', value: 'Copywriting' },
        { name: 'Other', value: 'Other' }
    ];

    const budgetList = [
        { name: '$5k - 10k', value: '$5k - 10k' },
        { name: '$10k - 15k', value: '$10k - 15k' },
        { name: '$15k - 20k', value: '$15k - 20k' },
        { name: '$20k - 30k', value: '$20k - 30k' },
        { name: '$30k - 50k', value: '$30k - 50k' },
        { name: '$50k +', value: '$50k +' },
        { name: 'Not sure', value: 'Not sure' },
    ];
    
    const model = fb.group({
      name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
      email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
      phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
      details: [data.details || '', [validators.Required()], { label: 'Project Details', type: 'textarea' }],
      interested: [data.interested || [], [], { label : 'I am interested in ...', type: 'checkboxList', options: interestedList, style: { inline: false } }],
      budget: [data.budget || '', [validators.Required('Please select your choice.')], { label : 'Available Budget ...', type: 'radioList', options: budgetList, style: { inline: false } }],
      attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }]
    }); 
    return model;
  }